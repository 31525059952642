import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from './createContext'

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () => window.matchMedia('(prefers-color-scheme: dark)').matches === true

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    darkMode: false,
    toggleMode: () => this.toggleMode(),
  }

  toggleMode() {
    this.setState(
      prevState => ({
        darkMode: !prevState.darkMode,
      }),
      () => {
        localStorage.setItem('darkMode', JSON.stringify(this.state.darkMode))
        window.gtag &&
          window.gtag('event', 'toggle', {
            event_category: 'Theme',
            event_label: this.state.darkMode ? 'Dark Mode' : 'Light Mode',
          })
      }
    )
  }

  componentDidMount() {
    const darkMode = JSON.parse(localStorage.getItem('darkMode'))
    if (darkMode) {
      this.setState({ darkMode: darkMode })
    } else if (supportsDarkMode()) {
      this.setState({ darkMode: true })
    }
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProvider
