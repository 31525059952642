module.exports = [{
      plugin: require('/Users/sean/Projects/Personal/seankozer.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/sean/Projects/Personal/seankozer.com/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-28296902-1"],"pluginConfig":{"head":false}},
    },{
      plugin: require('/Users/sean/Projects/Personal/seankozer.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
